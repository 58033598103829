<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="700">
            <v-card>
                <v-card-title class="subHeader headline grey lighten-2">Aktion hinzufügen</v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" >
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="aktion.name"
                                        filled
                                        :rules="namerule"
                                        label="Name"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="aktion.beschreibung"
                                        filled
                                        label="Beschreibung"></v-text-field>
                        </v-col>
                        <v-col cols="11">
                          <v-autocomplete v-model="aktion.basis"
                                          :items="aktionen"
                                          item-text="name"
                                          item-value="id"
                                          label="Auf basis von"
                                          persistent-h
                                          filled>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="1">
                          <v-btn class="mt-3" @click="aktion.basis = null" small fab color="accent"><v-icon color="white" >mdi-cancel</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-radio-group  v-model="aktion.schutz">
                            <v-radio key="0"
                                     color="accent"
                                     label="Markierte Personen darf jeder lösen"
                                     :value="0"></v-radio>
                            <v-radio key="1"
                                     color="accent"
                                     label="Markierte Personen werden für andere Nutzer gesperrt"
                                     :value="1"></v-radio>
                            <v-radio key="2"
                                     color="accent"
                                     label="Liste für alle Nutzer sperren"
                                     :value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn @click="createNewAktion()" :disabled="!valid" color="accent">Speichern</v-btn>
                  <v-btn outlined color="black" @click="updateDialog(false)">Abbrechen</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data() {
            return {
                aktion: { name: '', beschreibung: '', basis: 0, schutz: 1 },
                viewAktion: { id: 0, name: '', beschreibung: '', created: '', },
                aktionsPersonen: [],
                valid: false,
                radioGroup: 1,
                namerule: [
                    v => !!v || 'Bitte Aktionsnamen eintragen'
                ],

            }
        },
        watch: {

        },
        beforeMount() {

        },
        mounted() {
        },
        props: ['dialog'],

        computed: {
            ...mapState(['aktionen'])

        },
        methods: {
            createNewAktion: function () {
                this.$http.post('/api/Stammdaten/CreateAktion', this.aktion).then(response => {

                    var allAktionen = this.aktionen;
                    var res = response.data;
                     allAktionen.push(res);

                    this.$store.dispatch('setAktionen', {
                                data: allAktionen
                            });


                    this.updateDialog(false);

                }).catch((error) => console.log(error));

            },
            updateDialog: function (value) {
                this.$emit('update:dialog', value)
            },
            filterAktion: function (id) {
                var aktion = this.aktionen.filter(function (element) { return element.id == id })[0];
                return aktion;
            },
            dateTime: function () {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1; //January is 0!
                var yyyy = today.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd
                }

                if (mm < 10) {
                    mm = '0' + mm
                }

                today = mm + '/' + dd + '/' + yyyy;
                return today
            },
            updateEntry: function (index) {
                this.$http.post('/api/Stammdaten/updateEntry', this.person).then(response => {
                }).catch((error) => console.log(error));
            },

        },

    }
</script>

<style scoped>
</style>
